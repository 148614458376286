import axios from "axios";

const token = sessionStorage.getItem('astrof-token');

const base = 'https://api.services.astrofi.com/api';
const AXIOS = axios.create({
    baseURL: base,
    headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
    }
});


// export const fetchUserInfo = async () => {
//     try {
//         const response = await AXIOS.get('/admin/get_roles');
//         const { data } = response.data;
//         const { role_id } = data; 
//         sessionStorage.setItem('user-role', role_id); 
//         console.log(role_id)
//         return role_id;
//     } catch (error) {
//         console.error("Error fetching user info:", error);
//         throw error;
//     }
// };

export default AXIOS;
