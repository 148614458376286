import { useState } from 'react';
import AXIOS from '../API';
import { useNavigate } from 'react-router-dom';
import { toastMsg } from '../App';
import MainLogo from '../assets/main-logo.svg';
import Astro1 from '../assets/astro1.svg';
import Arrow from '../assets/w-r-arrow.svg';
import UserName from '../assets/username.svg';
import PasswordIcon from '../assets/password.svg';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await AXIOS.post('/admin/login', { username, password });
      const userData = response?.data?.data;
      const authToken = response?.data?.data?.token;
      const userName = response?.data?.data?.username;
      
      const { role_id } = userData;

      // Check if role_id exists
      if (!role_id) {
        setError('Role ID not found');
        return;
      }

      // Store token and role_id in sessionStorage
      sessionStorage.setItem('astrof-token', authToken);
      sessionStorage.setItem('user-role', role_id);
      sessionStorage.setItem('admin-name', userName);

      // Show success message
      toastMsg.success('Welcome To Astrofi Dashboard');

      // Navigate to home page after a delay
      setTimeout(() => {
        window.location.href = '/';
      }, 1900);
    } catch (error) {
      // Handle error response
      if (error.response) {
        if (error.response?.data?.errors?.username) {
          setError(error.response?.data?.errors?.username[0]);
        } else if (error.response?.data?.errors?.password) {
          setError(error.response?.data?.errors?.password[0]);
        } else {
          setError(error.response?.data?.message || 'Something went wrong!');
        }
      } else {
        setError('Network error. Please try again.');
      }
    }

    setLoading(false);
  };

  return (
    <div className="row sm-d-flex">
      <div className="col-lg-6 col-sm-6 purple-b">
        <div className="d-flex flex-column align-items-center justify-content-around h-100vh ">
          <img src={MainLogo} alt="" className="w-28" />
          <img src={Astro1} alt="" className="" />
        </div>
      </div>
      <div className="col-lg-6 col-sm-6 grey-b d-flex flex-column align-items-center justify-content-center ">
        <h1 className='text-center mb-5 p2-color'>LOG IN</h1>
        <p className='w-75 p2-color'>Lorem ipsum dolor sit amet consectetur. Tellus ipsum interdum dis vitae enim est. Rhoncus morbi nisi maecenas enim blandit curabitur eget leo donec. Ipsum lacus egestas purus sem. Posuere pellentesque ornare commodo pharetra</p>
        <form className=" px-5 reg-form " onSubmit={handleSubmit}>
          <div className="flex-col">
            <div className="form-control mb-4">
              <img src={UserName} alt="" />
              <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder='Username' />
            </div>
            <div className="form-control mb-4">
              <img src={PasswordIcon} alt="" />
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' />
            </div>
            {error && <div className="d-flex error-add-users"> <p className="error">{error}</p></div>}
            <button type="submit" className='btn next-btn w-100 mt-1' disabled={loading}>
              <label className="pb-1">Log in</label>
              <img src={Arrow} alt="" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;