import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import Sidebar from '../pages/layouts/Sidebar';
import LoginForm from '../pages/Login';
import Layout from '../pages/layouts/Layout';

// Lazy-loaded components
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Admin = lazy(() => import('../pages/Admin'));
const Analytics = lazy(() => import('../pages/Analytics'));
const Users = lazy(() => import('../pages/Users/Users'));
const Leads = lazy(() => import('../pages/Leads/Leads'));
const Website = lazy(() => import('../pages/Website'));
const Deliveries = lazy(() => import('../pages/Deliveries/Deliveries'));
const Categories = lazy(() => import('../pages/Categories/Categories'));
const Services = lazy(() => import('../pages/Services/Services'));
const Blogs = lazy(() => import('../pages/Blogs/Blogs'));
const Packages = lazy(() => import('../pages/Packages/Packages'));
const HomePage = lazy(() => import('../pages/StaticContent/HomePage/HomePage'));
const AboutPage = lazy(() => import('../pages/StaticContent/HomePage/AboutPage'));
const ContactRequest = lazy(() => import('../pages/ContactRequest'));
const Payments = lazy(() => import('../pages/Payments'));
const FinanceReport = lazy(() => import('../pages/FinanceReport'));
const LogOut = lazy(() => import('../pages/LogOut'));
const Category = lazy(() => import('./Categories/Categories'));
const EditCategory = lazy(() => import('./Categories/EditCategory'));
const AddCategory = lazy(() => import('./Categories/AddCategory'));
const ManageUser = lazy(() => import('../pages/Users/ManageUser'));
const AddService = lazy(() => import('../components/Services/AddService'));
const Service = lazy(() => import('./Services/Service'));
const AddPackage = lazy(() => import('../components/Packages/AddPackage'));
const Package = lazy(() => import('./Packages/Package'));
const ManageLead = lazy(() => import('../pages/Leads/ManageLead'));
const Orders = lazy(() => import('../pages/Orders/Orders'));
const Order = lazy(() => import('../components/Orders/Order'));
const OrderEdit = lazy(() => import('./Orders/OrderEdit'));
const AddDel = lazy(() => import('./Deliveries/AddDel'));
const ShowDel = lazy(() => import('./Deliveries/ShowDel'));
const AddDemo = lazy(() => import('./Deliveries/AddDemo'));
const AddBlog = lazy(() => import('./Blogs/AddBlog'));
const Blog = lazy(() => import('./Blogs/Blog'));
const ShowDemo = lazy(() => import('./Deliveries/ShowDemo'));
const AddDelOrder = lazy(() => import('./Orders/AddDelOrder'));
const AXIOS = lazy(() => import('../API'));
const AddOffer = lazy(() => import('./Offers/AddOffer'));
const AddOfferPackage = lazy(() => import('./Offers/AddOfferPackage'));
const Offer = lazy(() => import('./Offers/Offer'));
const OffersTab = lazy(() => import('./../pages/Offer/OffersTab'));
const OfferPromo = lazy(() => import('./Offers/OfferPromo'));
const NotificationPage = lazy(() => import('./../components/Notification/NotificationPage'));
const ContactRequests = lazy(() => import('../pages/ContactRequests/ContactRequests'));
const RequestPage = lazy(() => import('../pages/ContactRequests/RequestPage/RequestPage'));
const AddServiceCat = lazy(() => import('./../components/Categories/AddServiceCat'));
const SubscriptionPage = lazy(() => import('../pages/subscriptions/SubscriptionPage'));

export const Routee = () => {
  const token = sessionStorage.getItem('astrof-token');
  useEffect(() => {
    const interval = setInterval(() => {
      validateToken();
    },  1500000000); 
    return () => clearInterval(interval);
  }, []);

  const validateToken = async () => {
    try {
      const response = await AXIOS.post('/admin/refresh_token', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        sessionStorage.removeItem('astrof-token');
        console.log(token)
        // setToken(null);
      }
    } catch (error) {
      sessionStorage.removeItem('astrof-token');
      // setToken(null);
    }
  };
  let routes = token
    ? [
      {
        path: '/',
        element: (
          <Layout>
            <Dashboard />
          </Layout>
        )
      },
      {
        path: '/dashboard',
        element: (
          <Layout>
            <Dashboard />
          </Layout>
        )
      },
      {
        path: '/users',
        element: (
          <Layout>
            <Users />
          </Layout>
        )
      },
      {
        path: '/users/:id',
        element: (
          <Layout>
            <ManageUser/>
          </Layout>
        )
      },
      {
        path: '/leads/:id',
        element: (
          <Layout>
            <ManageLead/>
          </Layout>
        )
      },
      {
        path: '/leads',
        element: (
          <Layout>
            <Leads />
          </Layout>
        )
      },
      {
        path: '/website',
        element: (
          <Layout>
            <Website />
          </Layout>
        )
      },
      {
        path: 'categories/:id/add-service',
        element: (
          <Layout>
            <AddServiceCat />
          </Layout>
        ),
      }, 
      {
        path: '/categories/create',
        element: (
          <Layout>
            <AddCategory />
          </Layout>
        ),
      }, 
       {
        path: '/categories/:id',
        element: (
          <Layout>
            <Category />
          </Layout>
        ),
      },
      {
        path: '/analytics',
        element: (
          <Layout>
            <Analytics />
          </Layout>
        )
      },
      {
        path: '/deliveries',
        element: (
          <Layout>
            <Deliveries />
          </Layout>
        )
      },
      {
        path: '/deliveries/:id',
        element: (
          <Layout>
            <ShowDel />
          </Layout>
        )
      },
      {
        path: '/delivery/add',
        element: (
          <Layout>
            <AddDel/>
          </Layout>
        )
      },
      {
        path: '/deliveries/add/:id/:serviceid',
        element: (
          <Layout>
            <AddDelOrder/>
          </Layout>
        )
      },
      {
        path: '/delivery-demo/add',
        element: (
          <Layout>
            <AddDemo/>
          </Layout>
        )
      },
      {
        path: '/deliveries-demo/:id',
        element: (
          <Layout>
            <ShowDemo />
          </Layout>
        )
      },
      {
        path: '/categories',
        element: (
          <Layout>
            <Categories />
          </Layout>
        )
      },
      {
        path: '/services',
        element: (
          <Layout>
            <Services />
          </Layout>
        )
      },
      {
        path: '/services/add',
        element: (
          <Layout>
            <AddService/>
          </Layout>
        ),
      },
      {
        path: '/services/:id',
        element: (
          <Layout>
            <Service/>
          </Layout>
        ),
      },
      {
        path: '/packages',
        element: (
          <Layout>
            <Packages />
          </Layout>
        )
      },
      {
        path: '/packages/add',
        element: (
          <Layout>
            <AddPackage/>
          </Layout>
        ),
      },
      {
        path: '/packages/:id',
        element: (
          <Layout>
            <Package/>
          </Layout>
        ),
      },
      {
        path: '/offers',
        element: (
          <Layout>
            <OffersTab />
          </Layout>
        )
      },
      {
        path: '/offers/add',
        element: (
          <Layout>
            <AddOffer />
          </Layout>
        )
      },
      {
        path: '/offers-package/add',
        element: (
          <Layout>
            <AddOfferPackage />
          </Layout>
        )
      },
      {
        path: '/offer/:id',
        element: (
          <Layout>
            <Offer />
          </Layout>
        )
      },
      {
        path: '/offer/:id/promo',
        element: (
          <Layout>
            <OfferPromo />
          </Layout>
        )
      },

      {
        path: '/payments',
        element: (
          <Layout>
            <Payments />
          </Layout>
        )
      },
      {
        path: '/finance-report',
        element: (
          <Layout>
            <FinanceReport />
          </Layout>
        )
      },
      {
        path: '/payments',
        element: (
          <Layout>
            <Payments />
          </Layout>
        )
      },
      {
        path: '/admin',
        element: (
          <Layout>
            <Admin />
          </Layout>
        )
      },
      {
        path: '/static-content',
        element: (
          <Layout>
            <HomePage />
          </Layout>
        ),
      },
      {
        path: '/orders',
        element: (
          <Layout>
            <Orders />
          </Layout>
        ),
      },
      {
        path: '/order/:id',
        element: (
          <Layout>
            <Order />
          </Layout>
        ),
      },
      {
        path: '/order/edit/:id',
        element: (
          <Layout>
            <OrderEdit />
          </Layout>
        ),
      },
      {
        path: '/static-content/home',
        element: (
          <Layout>
            <HomePage />
          </Layout>
        ),
      },
      {
        path: '/static-content/about',
        element: (
          <Layout>
            <AboutPage />
          </Layout>
        ),
      },
      {
        path: '/blogs',
        element: (
          <Layout>
            <Blogs />
          </Layout>
        ),
      },
      {
        path: '/blogs/add',
        element: (
          <Layout>
            <AddBlog />
          </Layout>
        ),
      },
      {
        path: '/blogs/:id',
        element: (
          <Layout>
            <Blog />
          </Layout>
        ),
      },
      {
        path: '/notifications',
        element: (
          <Layout>
            <NotificationPage/>
          </Layout>
        ),
      },

      {
        path: '/contact-requests',
        element: (
          <Layout>
            <ContactRequests />
          </Layout>
        ),
      },
      // {
      //   path: '/contact-types',
      //   element: (
      //     <Layout>
      //       <ContactTypes />
      //     </Layout>
      //   ),
      // },
      {
        path: '/contact_requests/:id',
        element: (
          <Layout>
            <RequestPage />
          </Layout>
        ),
      },
      {
        path: '/subscriptions',
        element: (
          <Layout>
            <SubscriptionPage />
          </Layout>
        ),
      },
      ] 
      : [
        { path: '/login', element: <LoginForm /> },
        { path: '*', element: <Navigate replace to='/login' /> },
      ];

      return useRoutes(routes)
  // Wrap the routes with the Sidebar component
  // const routesWithSidebar = (
  //   <Sidebar>
  //     <Routes>
  //       {routes.map((route, index) => (
  //         <Route key={index} path={route.path} element={route.element} />
  //       ))}
  //     </Routes>
  //   </Sidebar>
  // );

  // return useRoutes(routesWithSidebar);
};
