
import React from 'react';
import Video from './../assets/Loader.mp4'
const Loader = () => {
    return (
      <div className='vid-div'>
      <video autoPlay loop muted src={Video} type='video/mp4' />
      </div>
      )
};
export default Loader