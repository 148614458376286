import React, { useState, useEffect } from 'react';
import {
    FaBars,
} from "react-icons/fa";
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import AXIOS from '../../API';
import Loader from '../../components/Loader';
import HomeIcon from '../../assets/house.svg';
import InfoIcon from '../../assets/about-us.svg';
import BlogsIcon from '../../assets/blogs.svg';
import DashboardIcon from '../../assets/dashboard.svg';
import UsersIcon from '../../assets/users.svg';
import LeadsIcon from '../../assets/leads.svg';
import WebsiteIcon from '../../assets/website.svg';
import CloseImg from '../../assets/close.svg';
import AnalyticsIcon from '../../assets/analytics.svg';
import DeliveriesIcon from '../../assets/delivery.svg';
import CategoriesIcon from '../../assets/categories.svg';
import ServicesIcon from '../../assets/services.svg';
import PackagesIcon from '../../assets/package.svg';
import OffersIcon from '../../assets/offers.svg';
import ContactIcon from '../../assets/contact-requset.svg';
import PaymentsIcon from '../../assets/payments.svg';
import FinanceIcon from '../../assets/finance.svg';
import AdminIcon from '../../assets/admin.svg';
import LogOutIcon from '../../assets/sign-out.svg';
import Logo from '../../assets/LogoWhite.svg';
import Astro from '../../assets/astro-bottom.svg';
import MiniLogo from '../../assets/mini-logo.svg';
import OrderIcon from '../../assets/order.svg';
import Sub from '../../assets/sub.svg';

const Sidebar = ({ children }) => {
  const userRole = sessionStorage.getItem('user-role');
  const token = sessionStorage.getItem('astrof-token');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [adminName, setAdminName] = useState('');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const name = sessionStorage.getItem('admin-name');
    setAdminName(name);
  }, []);

  const toggle = () => setIsOpen(!isOpen);
  const toggleSubmenu = () => setIsSubmenuOpen(!isSubmenuOpen);

  const handleLogout = () => {
    setIsConfirmOpen(true);
  };

  const handleConfirmLogout = () => {
    setIsLoading(true);
    sessionStorage.removeItem('astrof-token');
    navigate('/login');

    setIsConfirmOpen(false);
  };

  const handleCancelLogout = () => {
    setIsConfirmOpen(false);
  };

  const navItems = [
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: DashboardIcon
    },
    {
      path: '/users',
      name: 'Users',
      icon: UsersIcon
    },
    {
      path: '/leads',
      name: 'Leads',
      icon: LeadsIcon
    },
    {
      path: '/website',
      name: 'Website',
      icon: WebsiteIcon,
      submenu: [
        {
          path: '/static-content/home',
          name: 'Home',
          icon:HomeIcon
        },
        {
          path: '/static-content/about',
          name: 'About Us',
          icon:InfoIcon
        },
   
      ]
    },
    {
      path: '/blogs',
      name: 'Blogs',
      icon:BlogsIcon
    },
    {
      path: '/analytics',
      name: 'Analytics',
      icon: AnalyticsIcon
    },
    {
      path: '/orders',
      name: 'Orders',
      icon: OrderIcon
    },
    {
      path: '/deliveries',
      name: 'Deliveries',
      icon: DeliveriesIcon
    },
    {
      path: '/subscriptions',
      name: 'Subscriptions',
      icon: Sub
    },
    {
      path: '/categories',
      name: 'Categories',
      icon: CategoriesIcon
    },
    {
      path: '/services',
      name: 'Services',
      icon: ServicesIcon
    },
    {
      path: '/packages',
      name: 'Packages',
      icon: PackagesIcon
    },
    {
      path: '/offers',
      name: 'Offers',
      icon: OffersIcon
    },
    {
      path: '/contact-requests',
      name: 'Contact',
      icon: ContactIcon
    },
    {
      path: '/payments',
      name: 'Payments',
      icon: PaymentsIcon
    },

  ];

  if (token && userRole === '1') {
    navItems.push({
      path: '/admin',
      name: 'admin',
      icon: AdminIcon
    });
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="container-sidebar">
        <div style={{ width: isOpen ? "16%" : "4%" }} className="sidebar">
          <div className="bars" style={{ marginRight: isOpen ? '0' : '17px' }}>
            <FaBars onClick={toggle} />
          </div>
          <div className="top_section">
            <img className="logo" src={isOpen ? Logo : MiniLogo}  style={{ marginRight: isOpen ? '0' : '4px' }}/>
          </div>
          <h2 style={{ textAlign: 'center', fontSize: '16px', display: isOpen ? "block" : "none" }}>{adminName}</h2>
          {
            navItems.map((item, index) => (
              item.submenu ? (
                <div key={index}>
                  <div
                    className={`link ${window.location.pathname.startsWith(item.path) ? 'active' : ''}`}
                    style={{ padding: isOpen ? '' : '10px 15px' }}
                    onClick={toggleSubmenu}
                  >
                    <img className="icon" src={item.icon} alt="" />
                    <div style={{ display: isOpen ? "block" : "none" }} className="cursor-pointer link_text">{item.name}</div>
                  </div>
                  {isSubmenuOpen && item.submenu.map((subItem, subIndex) => (
                    <NavLink 
                      to={subItem.path}
                      key={subIndex}
                      className={`link cursor-pointer sub-link ${window.location.pathname === subItem.path ? 'active' : ''}`}
                      style={{ padding: isOpen ? '' : '10px 15px'  }}
                    >
                      <img className="icon" src={subItem.icon} alt="" />
                      <div  style={{ display: isOpen ? "block" : "none" }} className="link_text cursor-pointer">{subItem.name}</div>

                    </NavLink>
                  ))}
                </div>
              ) : (
                <NavLink 
                  to={item.path}
                  key={index}
                  className={`link cursor-pointer ${window.location.pathname === item.path ? 'active' : ''}`}
                  style={{ padding: isOpen ? '' : '10px 15px' }}
                >
                  <img className="icon" src={item.icon} alt="" />
                  <div style={{ display: isOpen ? "block" : "none" }} className="cursor-pointer link_text">{item.name}</div>
                </NavLink>
              )
            ))
          }
          <span className={`link logout-span`} onClick={handleLogout} style={{ padding: isOpen ? '' : '10px 20px' }}>
            <img className="icon" src={LogOutIcon} alt="" />
            <div style={{ display: isOpen ? "block" : "none" }} className="link_text">Sign Out</div>
          </span>
        </div>

        <Dialog show={isConfirmOpen} onHide={() => setIsConfirmOpen(false)} open={isConfirmOpen}>
          <DialogTitle>Log Out</DialogTitle>
          <DialogContent>
            <h3 className='text-main text-2xl'>
              Are you sure you want to log out?
            </h3>
          </DialogContent>
          <DialogActions>
            <div className='btn cancel-btn' onClick={handleConfirmLogout}>
              <img src={CloseImg} alt="" />
            </div>
            <button key={16} className="w-50 btn active" onClick={handleConfirmLogout} style={{ padding: isOpen ? '' : '10px 15px' }}>Yes</button>
          </DialogActions>
        </Dialog>
        <main style={{ width: isOpen ? '80%' : '92%' }}>{children}</main>
      </div>
    </>
  );
};

export default Sidebar;
