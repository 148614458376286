import React,{Suspense} from 'react';
import './App.css';
import { BrowserRouter, Route, Navigate } from 'react-router-dom';
import  {Routee}  from './components/Routee';
import { toast, Toaster } from 'react-hot-toast';
import Loader from './components/Loader';
import ScrollToTop from './components/ScrollTop';
export const toastMsg = {
  error: function (msg) {
    toast.error(msg, {
      duration: 3000,
      style: {
        background: '#ef4444',
        color: 'white',
        padding: '16px',
        fontWeight: 'bold',
      },
      position: 'top-right',
    });
  },
  warn: function (msg) {
    toast.error(msg, {
      duration: 3000,
      style: {
        background: '#FBC02D',
        color: 'white',
        padding: '16px',
        fontWeight: 'bold',
      },
      position: 'top-right',
    });
  },
  success: function (msg) {
    toast.success(msg, {
      duration: 3000,
      style: {
        background: '#22c55e',
        color: 'white',
        padding: '16px',
        fontWeight: 'bold',
      },
      position: 'top-right',
    });
  },
};
function App() {
  return (
    <>
      <BrowserRouter>
      <ScrollToTop/>
      <Suspense fallback={<div>
        <Loader/>
      </div>}>
          <Routee/>
          </Suspense>
      </BrowserRouter>
      <Toaster />
    </>
  );
}

export default App;